#root {
    height: 100%;
}

.main {
    height: 100%;
}

.mainContainer {
    padding-top: 70px;
    height: 100%;
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto',sans-serif;
}

#form-container{
    margin-top: 5vh;
    background: white;
}

.required{
    color:red;
}


#demo textarea{
    width: 100%;
}

#demo .bad{
    border: 2px solid red !important;
    background: #fff2f2;
}

.subtitle img{
    max-width: 100%;
}

.logo{
    max-width: 100%;
}