#errorPage{
    text-align:center;
    margin-top: 5vh;
}

#errorPage i{
    font-size:5em;
    color:#f35959;
}

