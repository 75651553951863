
#stripes{
    width:100%;
    height: 100%;
    z-index:-2;
    overflow: hidden;
    transform-origin: 0;
    transform: skewY(-12deg);
    opacity: 0.3;
    background: -webkit-linear-gradient(300deg,#53f 15%,#05d5ff 70%,#a6ffcb 94%) !important;
    background: linear-gradient(150deg,#53f 15%,#05d5ff 70%,#a6ffcb 94%) !important;
}

#stripes, #stripes span{
    position: absolute;
}

#stripes span{
    height: 40px;
}

#stripes :nth-child(1){
    width: 50%;
    left: 50%;
    top:40px;
    background:#4c29ff;
}

#stripes :nth-child(2){
    width: 14%;
    right: 0;
    bottom:40px;
    background: #2be7ff;
}

#stripes :nth-child(3){
    width: 25%;
    bottom: 0;
    right: 0;
    background: #a1ffc8;
}

@media (min-width:670px){
    #stripes span{
        height:150px;
    }
    #stripes :nth-child(1){
        top: 0;
    }
    #stripes :nth-child(2){
        width: 16.66667%;
        top: 300px;
        bottom: auto;
        background: #0dcfff;
    }
    #stripes :nth-child(3){
        width: 33.33333%;
        right: auto;
        background: #11bdff;
    }
}

@media (min-width: 880px) {
    #stripes span {
        height: 190px;
    }

    #stripes :nth-child(1) {
        width: 33.33333%;
        left: -16.66666%;
        background: #53f;
    }

    #stripes :nth-child(2) {
        width: 33.33333%;
        top: 0;
        left: 16.66666%;
        right: auto;
        background: #4553ff;
    }

    #stripes :nth-child(3) {
        width: 33.33333%;
        left: 49.99999%;
        bottom: auto;
        background: #4f40ff;
    }

    #stripes :nth-child(4) {
        width: 33.33333%;
        top: 380px;
        right: -16.66666%;
        background: #25ddf5;
    }

    #stripes :nth-child(5) {
        width: 33.33333%;
        bottom: 0;
        background: #1fa2ff;
    }
}

#main-background{
    width:100%;
    height: 100%;
    z-index:-2;
    position:fixed;
    background: #f3f3f3;
}

.theme-bblack{
    background: #212121;
}

.theme-blight{
    background: #f3f3f3;
}

.theme-colorful{

}