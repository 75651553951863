#root {
    height: 100%;
}

.main {
    height: 100%;
}

.mainContainer {
    padding-top: 70px;
    height: 100%;
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
}

#form-container{
    margin-top: 5vh;
}

#form-container .card-header{
    font-size: 1.5em;
    text-align: center;

}

#form-container .card-body img{
    width: 100%;
    margin-bottom:20px;
    border-radius: 5px;
}

#form-container .info{
    text-align: center;
    -webkit-animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

#price{
    font-family: 'OSWALD';
}

#price-description{
    color:#666;
}

@-webkit-keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
@keyframes tracking-in-expand-fwd {
    0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px);
        transform: translateZ(-700px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
